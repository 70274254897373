<template>
  <!--  delivery status chip -->
  <v-chip v-bind="$attrs" :color="color">{{ text }}</v-chip>
</template>

<script>
export default {
  props: {
    status: {},
  },

  data : () => ({
    statuses : {
      pending: {
        text: "Pendente",
        color: "secondary",
      },
      processing: {
        text: "Processando",
        color: "warning",
      },
      in_route: {
        text: "Em Rota",
        color: "primary",
      },
      delivered: {
        text: "Entregue",
        color: "success",
      },
      canceled: {
        text: "Cancelado",
        color: "error",
      },
    },
  }),

  computed: {
    color() {
      return this.statuses[this.status].color;
    },

    text() {
      return this.statuses[this.status].text;
    }
  },
};
</script>

<style></style>
