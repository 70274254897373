<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Atualizar Status</span>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <app-text-field
              autofocus
              v-model="number"
              v-debounce:300ms="search"
              label="Código"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
        >
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      number: null,
      delivery: null,

      statusOptions: {
        pending: { value: "processing", text: "Processando", color: "warning" },
        processing: { value: "in_route", text: " Em Rota", color: "primary" },
        in_route: { value: "delivered", text: "  Entregue", color: "success" },
        delivered: { value: "delivered", text: "  Entregue", color: "success" },
        canceled: { value: "canceled", text: "  Cancelada", color: "error" },
      },
    };
  },

  computed: {
 

    hasDelivery() {
      return this.delivery !== null;
    },

    isCanceled() {
      return this.hasDelivery && this.delivery.status === "canceled";
    },
    isDelivered() {
      return this.hasDelivery && this.delivery.status === "delivered";
    },
    isPending() {
      return this.hasDelivery && this.delivery.status === "pending";
    },
    canUpdate() {
      return (
        (this.hasDelivery && !this.isCanceled) ||
        !this.isDelivered ||
        !this.isPending
      );
    },
  },

  methods: {
    open() {
      this.dialog = true;
    },

    search(number) {
      this.$http
        .index("sale/delivery", { number: number })
        .then(async (response) => {
          await (this.delivery = response.deliveries.data[0] || null);

          if (!this.hasDelivery) {
            this.notFound();
          }

          if (this.hasDelivery) {
            this.validate();
          }
        });
    },

    reset() {
      this.number = null;
      this.delivery = null;
    },

    validate() {
      if (this.isDelivered) {
        this.showAlert("Já foi entregue", "error");

        this.reset();
      }

      if (this.isCanceled) {
        this.showAlert("Entrega foi cancelada", "error");
        this.reset();
      }
      if (this.isPending) {
        this.showAlert("Entrega ainda não foi processada pelo caixa", "error");
        this.reset();
      }
      if (this.canUpdate) {
        this.update();
      }
    },

    notFound() {
      this.showAlert("Não encontrado", "error", 9000);

      this.reset();
    },

    update() {
      let newStatus = this.statusOptions[this.delivery.status];

      this.$http
        .update("sale/delivery", this.delivery.id, {
          status: newStatus.value,
        })
        .then((response) => {
          this.showAlert(
            `Entrega Nº${this.number} atualizada para ${newStatus.text}`,
            newStatus.color
          );
          this.$emit("update");
          this.reset();
        })
        .catch((error) => {});
    },

    showAlert(message, color = "success", timeout = 3000) {
      this.$snackbar({
        message: message,
        props: {
          color: color,
          timeout: timeout,
        },
      });
    },
  },
};
</script>

<style>
</style>